









































import { useWarehouse } from "@/hooks";
import { FormValue } from "@/hooks/useWarehouse";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { BranchWarehouseResponseDto } from "@/models/interface/branch";
import { WarehouseCreateRequestDto } from "@interface/warehouse";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  components: {
    SelectBranch: () => import("@/components/custom/select/SelectBranch.vue"),
  },
})
export default class FormModals extends Mixins(MNotificationVue) {
  @Prop({ type: Boolean, default: false, required: false })
  visible!: boolean;

  @Ref("formModel")
  formModel!: FormModel;

  @Prop({ type: String, required: false, default: "" })
  warehouseId!: string;

  loading = false;

  form: FormValue = {
    address: "",
    branchName: "",
    branchWarehouseId: "",
    name: "",
  };

  formRules = {
    address: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    branchName: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    name: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  get isUpdate(): boolean {
    return !!this.warehouseId;
  }

  get title(): string {
    const lblEdit = this.$t("lbl_edit_x", {
      x: this.$t("lbl_warehouse"),
    }).toString();
    const lblCreate = this.$t("lbl_create_x", {
      x: this.$t("lbl_warehouse"),
    }).toString();

    return this.isUpdate ? lblEdit : lblCreate;
  }

  @Watch("warehouseId")
  onChangeWarehouseId(): void {
    if (!this.warehouseId) return;
    this.getDetail(this.warehouseId);
  }

  getDetail(warehouseId: string): void {
    const { findById, mapDetailToForm } = useWarehouse();
    findById(warehouseId).then(res => {
      this.form = mapDetailToForm(res);
    });
  }

  emitClose(): void {
    this.$emit("close");
  }

  closeModal(): void {
    this.form.branchWarehouseId = "";
    this.formModel.resetFields();
    this.emitClose();
  }

  validateForm(): void {
    this.formModel.validate(valid => {
      if (!valid) return;

      if (this.isUpdate) {
        this.handleUpdate(this.warehouseId, this.form);
      } else {
        this.handleCreate(this.form);
      }
    });
  }

  handleCreate(form: FormValue): void {
    const { create, mapFormToCreateDto } = useWarehouse();
    const req: WarehouseCreateRequestDto = mapFormToCreateDto(form);
    this.loading = true;
    create(req)
      .then(({ name }) => {
        this.showNotifSuccess("notif_create_success", {
          documentNumber: name,
        });
        this.emitSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleUpdate(warehouseId: string, form: FormValue): void {
    const { update, mapFormToCreateDto } = useWarehouse();
    const req: WarehouseCreateRequestDto = mapFormToCreateDto(form);
    this.loading = true;
    update(warehouseId, req)
      .then(({ name }) => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: name,
        });
        this.emitSuccess();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChangeBranch(e?: Option<BranchWarehouseResponseDto>): void {
    this.form.branchWarehouseId = e?.meta?.id || "";
  }

  emitSuccess(): void {
    this.form.branchWarehouseId = "";
    this.formModel.resetFields();
    this.$emit("success");
  }
}
