






























































import { FormModals } from "@/components/Warehouse";
import { APagination, useWarehouse } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FIRST_PAGE } from "@/mixins/MQueryPage.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { WarehouseResponseDto } from "@/models/interface/warehouse";
import { SorterProps } from "@/types";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    FormModals,
  },
})
export default class IndexPage extends Mixins(MNotificationVue) {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  inputSearch = (this.$route.query?.q || "").toString();

  modal = {
    show: false,
    warehouseId: "",
    toggle(): void {
      this.show = !this.show;
    },
  };

  loading = false;

  dataWarehouse: Pagination<WarehouseResponseDto> = {
    data: [],
    currentPage: 0,
    totalElements: 0,
    totalPages: 0,
  };

  pagination = {
    sorts: "",
    page: +(this.$route.query?.p || FIRST_PAGE),
    limit: +(this.$route.query?.l || DEFAULT_PAGE_SIZE),
    reset(): void {
      this.page = FIRST_PAGE;
    },
  };

  columns = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchWarehouse.name",
      sorter: true,
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_name"),
      dataIndex: "name",
      width: 200,
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
    {
      title: this.$t("lbl_address"),
      dataIndex: "address",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_action"),
      key: "action",
      width: 100,
      scopedSlots: { customRender: "edit" },
    },
  ];

  mounted(): void {
    const params = this.buildParams();
    this.findData(params);
  }

  buildParams(): RequestQueryParams {
    const { searchBy } = useWarehouse();
    const params = new RequestQueryParams();

    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;
    params.sorts = this.pagination.sorts;

    if (this.inputSearch) {
      params.search = searchBy({
        name: this.inputSearch,
        address: this.inputSearch,
        branchName: this.inputSearch,
      });
    }

    return params;
  }

  updateRouteQuery(values: Record<string, string> = {}): void {
    this.$router.replace({
      name: "logistic.warehouse",
      query: {
        ...this.$route.query,
        ...values,
      },
    });
  }

  handleSearch(): void {
    this.pagination.reset();
    const params = this.buildParams();
    this.findData(params);
    this.updateRouteQuery({
      p: this.pagination.page.toString(),
      q: this.inputSearch,
    });
  }

  findData(params?: RequestQueryParamsModel): void {
    const { findAll } = useWarehouse();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.dataWarehouse = res;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleReset(): void {
    this.inputSearch = "";
    this.pagination.reset();
    const params = this.buildParams();
    this.findData(params);
    this.updateRouteQuery({
      p: this.pagination.page.toString(),
      q: this.inputSearch,
    });
  }

  handleEdit(warehouseId: string): void {
    this.modal.toggle();
    this.modal.warehouseId = warehouseId;
  }

  onTableChange(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;
    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;

    if (order && order === "ascend") {
      this.pagination.sorts = `${columnKey}:asc`;
    } else if (order && order === "descend") {
      this.pagination.sorts = `${columnKey}:desc`;
    } else {
      this.pagination.sorts = "";
    }

    const params = this.buildParams();

    this.findData(params);
    this.updateRouteQuery({
      p: this.pagination.page.toString(),
      l: this.pagination.limit.toString(),
    });
  }

  handleClose(): void {
    this.modal.warehouseId = "";
    this.modal.toggle();
  }

  handleSuccess(): void {
    this.modal.toggle();
    this.modal.warehouseId = "";
    const params = this.buildParams();
    this.findData(params);
  }
}
